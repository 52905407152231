<template>
  <div class="main-content">
    <base-bread-crumbs/>
    <router-view></router-view>
  </div>
</template>

<script>
import BaseBreadCrumbs from '../ui/layout/BaseBreadCrumbs'
export default {
  name: 'EditPage',
  components: { BaseBreadCrumbs },
}
</script>
